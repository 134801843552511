import _ from 'lodash';

// **  Initial State
const initialState = {
  userData: JSON.parse(localStorage.getItem('userData')) || {},
  userInfo: {},
  permissions: {},
  rolePermissions: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, userData: action.data };
    case 'LOGOUT':
      return { ...state, userData: {}, userInfo: {} };
    case 'ADD_USER_INFO':
      return { ...state, userInfo: action.data };
    case 'UPDATE_USER_INFO':
      return { ...state, userInfo: { ...state.userInfo, ...action.data } };
    case 'CHANGE_AVATAR_URL':
      return { ...state, userInfo: { ...state.userInfo, ...action.data } };
    case 'REMOVE_USER_INFO':
      return { ...state, userInfo: {} };
    case 'ADD_PERMISSIONS':
      return {
        ...state,
        permissions: { ...state.permissions, ...action.data },
      };
    case 'UPDATE_PERMISSIONS':
      return {
        ...state,
        permissions: {
          ...state.permissions,
          ...action.data,
        },
      };
    case 'REMOVE_PERMISSIONS':
      return {
        ...state,
        permissions: {},
      };
    case 'ADD_ROLE_PERMISSIONS':
      return {
        ...state,
        rolePermissions: { ...state.rolePermissions, ...action.data },
      };
    case 'UPDATE_ROLE_PERMISSIONS':
      return {
        ...state,
        rolePermissions: { ...state.rolePermissions, ...action.data },
      };
    case 'REMOVE_ROLE_PERMISSIONS':
      return {
        ...state,
        rolePermissions: {},
      };
    default:
      return state;
  }
};

export default authReducer;
