const initialState = {
  menuItems: [],
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_APP_MENU':
      return { ...state, menuItems: [...action.data] };
    case 'REMOVE_APP_MENU':
      return { ...state, menuItems: [] };
    default:
      return state;
  }
};

export default menuReducer;
