/* eslint-disable multiline-ternary */
// **  Initial State
const initialState = {
  hide_myr: true,
  hide_usd: false,
  hide_prefund: false,
  pda: {},
  total_myr: 0,
  total_usd: 0,
  total_sst: 0,
  roe: {
    USD: 0.0,
    MYR: 0.0,
  },
  headerLogo: {},
};

const pdaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PDA_DATA':
      return { ...state, ...action.data };
    case 'ADD_PDA_ITEM':
      return { ...state, pda: { ...state.pda, ...action.data } };
    case 'UPDATE_PDA_ITEM':
      return {
        ...state,
        pda: {
          ...state.pda,
          [action.key]: state.pda[action.key].map((item) => {
            return item.id === action.data.id
              ? { ...item, ...action.data }
              : item;
          }),
        },
      };
    case 'CLONE_PDA_ITEM':
      return {
        ...state,
        pda: {
          ...state.pda,
          [action.key]: [...state.pda[action.key], action.data],
        },
      };
    case 'REMOVE_PDA_ITEM':
      return {
        ...state,
        pda: {
          ...state.pda,
          [action.key]: state.pda[action.key].filter(
            (list) => list.id !== action.data.id
          ),
        },
      };
    case 'UPDATE_TOTAL_MYR':
      return { ...state, total_myr: action.data };
    case 'UPDATE_TOTAL_USD':
      return { ...state, total_usd: action.data };
    case 'UPDATE_TOTAL_SST':
      return { ...state, total_sst: action.data };
    case 'TOGGLE_HIDE_USD':
      return { ...state, hide_usd: action.data };
    case 'TOGGLE_HIDE_MYR':
      return { ...state, hide_myr: action.data };
    case 'TOGGLE_HIDE_PREFUND':
      return { ...state, hide_prefund: action.data };
    case 'UPDATE_ROE':
      return { ...state, roe: action.data };
    case 'SET_PDA_HEADER_LOGO':
      return { ...state, headerLogo: action.data };
    case 'CLEAR_PDA':
      return initialState;
    default:
      return state;
  }
};

export default pdaReducer;
