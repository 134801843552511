import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import GraphQLClient from './utility/GraphQLClient';
import { Provider } from 'react-redux';
import { store } from './redux/storeConfig/store';

import ability from './configs/acl/ability';
import { ToastContainer } from 'react-toastify';
import { AbilityContext } from './utility/context/Can';
import { ThemeContext } from './utility/context/ThemeColors';
import { IntlProviderWrapper } from './utility/context/Internationalization';

import Spinner from './@core/components/spinner/Fallback-spinner';

import './@core/components/ripple-button';
import './@fake-db';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

import 'react-perfect-scrollbar/dist/css/styles.css';

import '@styles/react/libs/toastify/toastify.scss';

import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

import * as serviceWorker from './serviceWorker';

const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
  <ApolloProvider client={GraphQLClient}>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <IntlProviderWrapper>
              <LazyApp />
              <ToastContainer newestOnTop />
            </IntlProviderWrapper>
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
