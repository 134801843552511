// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import auth from './auth';
import navbar from './navbar';
import layout from './layout';
import menu from './menu';
import chat from '@src/views/apps/chat/store/reducer';
import todo from '@src/views/apps/todo/store/reducer';
import email from '@src/views/apps/email/store/reducer';
import calendar from '@src/views/apps/calendar/store/reducer';
import pda from './pda';
import costnmargin from './constnmargin';
import invoiceItem from './invoice';

const rootReducer = combineReducers({
  auth,
  pda,
  todo,
  chat,
  email,
  navbar,
  layout,
  calendar,
  menu,
  invoiceItem,
  costnmargin,
});

export default rootReducer;
