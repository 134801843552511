const initialState = {
  items: [],
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_INVOICE_ITEM':
      return { ...state, items: [...state.items, action.data] };
    case 'UPDATE_INVOICE_ITEM':
      return {
        ...state,
        items: state.items.map((item) => {
          return item.id === action.data.id
            ? { ...item, ...action.data }
            : item;
        }),
      };
    case 'REMOVE_INVOICE_ITEM':
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.data.id),
      };
    case 'SET_INVOICE_ITEMS':
      return {
        ...state,
        items: [...action.data],
      };
    case 'CLEAR_INVOICE_ITEMS':
      return {
        ...state,
        items: [],
      };
    default:
      return state;
  }
};

export default invoiceReducer;
