/* eslint-disable multiline-ternary */

const initialState = {
  cnm: {},
  roe: {
    USD: 0.0,
    MYR: 0.0,
  },
};

const cnmReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CNM_DATA':
      return { ...state, ...action.data };
    case 'ADD_CNM_ITEM':
      return { ...state, cnm: { ...state.cnm, ...action.data } };
    case 'UPDATE_CNM_ITEM':
      return {
        ...state,
        cnm: {
          ...state.cnm,
          [action.key]: state.cnm[action.key].map((item) => {
            return item.id === action.data.id
              ? { ...item, ...action.data }
              : item;
          }),
        },
      };
    case 'CLONE_CNM_ITEM':
      return {
        ...state,
        cnm: {
          ...state.cnm,
          [action.key]: [...state.cnm[action.key], action.data],
        },
      };
    case 'REMOVE_CNM_ITEM':
      return {
        ...state,
        cnm: {
          ...state.cnm,
          [action.key]: state.cnm[action.key].filter(
            (list) => list.id !== action.data.id
          ),
        },
      };
    case 'UPDATE_CNM_ROE':
      return { ...state, roe: action.data };
    case 'CLEAR_CNM':
      return initialState;
    default:
      return state;
  }
};

export default cnmReducer;
