/*eslint-disable */
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  from,
} from '@apollo/client';
import { get } from 'lodash';
import { onError } from '@apollo/client/link/error';

const { REACT_APP_GATEWAY_URI } = process.env;

const httpLink = new HttpLink({
  uri: REACT_APP_GATEWAY_URI,
  credentials: 'include',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err && err.statusCode && err.statusCode === 401) {
        localStorage.clear();
      }
    }
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const AuthToken = JSON.parse(localStorage.getItem('userData'));
  const token = get(AuthToken, 'accessToken', null);
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  link: from([errorLink, authMiddleware.concat(httpLink)]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getJobDocsByJobId: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          getAllReceiptsByJobId: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  queryDeduplication: false,
});

export default client;
